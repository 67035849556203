<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div style="height: inherit">
      <!-- Overlay -->

      <!-- Prodcuts -->
      <section :class="itemView" class="grid-view" >
        <b-card
            v-for="story in stories"
            :key="story.story.mediaId"
            class=""
            no-body
        >
          <div class="item-img text-center pt-0 position-relative">
            <b-link class="w-100 ">
              <b-badge
                  class="product-badge p-1"
                  variant="dark"
              >
                {{createJalaliDate(story.story.updateDate.slice(0,10))}}
              </b-badge>
              <b-img
                  v-if="story.story.priority === 2"
                  fluid
                  class="card-img-top"
                  :src="'https://api.maxterms.com/media/gallery/story/'+story.story.pictureUrl"
              />
              <video
                  v-else
                  class="col-12"
                  controls
                  style="max-height: 30rem;"
              >
                <source
                    size=""
                    :src="'https://api.maxterms.com/media/gallery/story/'+story.story.pictureUrl"
                    type=""
                />
              </video>
            </b-link>
          </div>

          <!-- Product Details -->
          <b-card-body>

          </b-card-body>

          <!-- Product Actions -->
          <div class="item-options text-center p-0">
            <b-button
                variant="info"
                tag="a"
                class="btn-cart w-50 rounded-0"
                @click.prevent="$router.push({ name: 'apps-story-reports', params: { id: story.story.mediaId }})"
            >
              <feather-icon
                  icon="UsersIcon"
                  class="mr-1"
                  size="20"
              />
              <span>بازدیدکننگان </span>
              <span>( {{story.viewersCount}} )</span>
            </b-button>
            <b-button
                class=" btn-cart w-50 rounded-0"
                variant="danger"
                v-b-modal.modal-delete
                @click="showModal(story.story.mediaId)"
            >
              <feather-icon icon="TrashIcon" size="20" class="" />
            </b-button>
          </div>
        </b-card>
      </section>

      <!-- Pagination -->
      <section>
        <b-row>
          <b-col cols="12">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalCount"
                per-page="9"
                first-number
                align="center"
                last-number
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text >
                <feather-icon style="transform: rotate(180deg)"
                              icon="ChevronLeftIcon"
                              size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    </div>

    <b-modal
        id="modal-delete"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteMedia(deleteItem)"
    >
      <span>حذف شود؟</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BOverlay,BBadge,BModal
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ECommerceShopLeftFilterSidebar from "@/views/apps/e-commerce/e-commerce-shop/ECommerceShopLeftFilterSidebar";
import { useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData } from "@/views/apps/e-commerce/e-commerce-shop/useECommerceShop";
import { useEcommerceUi } from "@/views/apps/e-commerce/useEcommerce";
import {MediaDeleteByPictureUrlRequest, ProductGetAllRequest} from "@/libs/Api/Product";
import {ProductDeleteProductRequest} from "@/libs/Api/Product";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ProductCategoryGetAllRequest} from "@/libs/Api/Product";
import Helper from "@/libs/Helper";
import {ProductCategoryGetAllProductCategoriesRequest} from "@/libs/Api/Product";
import {StoryGetAllStoriesRequest} from "@/libs/Api/Story";


export default {
  name: "StoriesList",
  title: "لیست استوری ها - پنل ادمین مکس ",
  data(){
    return{
      stories:null,
      showOverlay:false,
      totalCount:null,
      productCategories:null,
      deleteItem:null,
      count: 9,
      currentPage:1,
    }
  },
  async created(){
    await this.getStories(this.currentPage , this.count);
  },
  methods:{
    async getStories(pageNumber,count){
      let _this = this;
      _this.showOverlay = true;

      let data={
        pageNumber:pageNumber,
        count:count
      }

      let storyGetAllStoriesRequest = new StoryGetAllStoriesRequest(_this);
      storyGetAllStoriesRequest.setParams(data)
      await storyGetAllStoriesRequest.fetch(function (content){
        _this.showOverlay = false;
        _this.stories = content.stories;
        _this.totalCount = content.storiesCount;
      } , function (content){
        _this.showOverlay = false;
        console.log(content)
      });
    },
    async deleteMedia(param){
      let _this = this;
      _this.showOverlay = true

      let mediaDeleteByPictureUrlRequest = new MediaDeleteByPictureUrlRequest(_this);
      mediaDeleteByPictureUrlRequest.setSocialMediaId(param);
      await mediaDeleteByPictureUrlRequest.fetch(function (content){
        _this.showOverlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `حذف شد.`,
          },
        })
        window.location.reload();
      },function (error){
        _this.showOverlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `عملیات انجام نشد.`,
          },
        })
        console.log(error)
      })
    },
    showModal(param){
      let _this = this;
      _this.deleteItem = param
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("fa-IR",);
    },
  },
  watch: {
    currentPage: function (nv, ov) {
      this.getStories(nv,this.count);
    },
    // SFC
  },
  setup() {
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination()

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const {
      itemView, itemViewOptions, totalProducts,
    } = useShopUi()

    const { products, fetchProducts } = useShopRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      fetchProducts({
        q: filters.value.q,
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
      })
          .then(response => {
            products.value = response.data.products
            totalProducts.value = response.data.total
          })
    }

    fetchShopProducts()

    watch([filters, sortBy], () => {
      fetchShopProducts()
    }, {
      deep: true,
    })

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      products,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BOverlay,
    BBadge,
    BModal,
  },
}
</script>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
img{
  height: 30rem!important;
}
.grid-view{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  margin-top: 2rem;
}
@media (max-width: 576px) {
  .grid-view{
    grid-template-columns: 1fr;
  }

}
</style>
